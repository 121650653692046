import React, { Component } from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Product from '../components/product';
import Pagination from '../components/pagination';
import Category from '../components/category';

class PostList extends Component {
  render() {
    const data = this.props.data;
    const posts = data.allWordpressPost;
    const { pageInfo } = posts;
    const { pageCount, currentPage } = pageInfo;
    return (
      <Layout showHeader={true}>
        {/* TODO: SEO */}
        <SEO title="Here Hear 聽聽" />

        <Product edges={posts.edges} />

        <div style={{ margin: 20 }}>
          <Pagination
            totalPage={pageCount}
            activePage={currentPage}
            baseUrl={`/posts/page`}
          />
        </div>

        <Category edges={data.allWordpressCategory.edges} />

        <style jsx>{``}</style>
      </Layout>
    );
  }
}

export default PostList;

export const pageQuery = graphql`
  query($limit: Int!, $skip: Int!) {
    allWordpressCategory {
      edges {
        node {
          wordpress_id
          name
          slug
        }
      }
    }
    allWordpressPost(
      limit: $limit
      skip: $skip
      sort: { order: DESC, fields: date }
    ) {
      pageInfo {
        pageCount
        perPage
        currentPage
      }
      edges {
        node {
          title
          slug
          categories {
            wordpress_id
            id
            name
            slug
          }
          tags {
            id
            name
            slug
          }
          jetpack_featured_media_url
        }
      }
    }
  }
`;
